<template>
  <BodySimple>
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/ai-robot.png" class="h-20 inline-block" />
        {{ bot.name }}
      </h2>
    </template>
    <template #default>
      <div
        v-show="Object.values(res).length"
        class="
          p-3
          bg-coolGray-800
          text-coolGray-100
          rounded-md
          mb-4
          max-h-screen
          overflow-auto
        "
      >
        <pre class="" dir="ltr">{{ pretty(res) }}</pre>
      </div>
      <div class="p-3 bg-blueGray-100 border rounded-md border-blueGray-200 mb-5">
        <form action="" @submit.prevent="submit">
          <MnrSelect
            v-model="inputs.method"
            :options="options.methods"
            placeholder="انتخاب عملیات"
          />
          <div class="flex flex-wrap sm:flex-nowrap my-3" v-if="inputs.method != 'off'">
            <MnrCheckSlider
              v-model:checked="inputs.changeServer"
              class="w-full sm:w-4/12"
            >
              تغییر سرور
            </MnrCheckSlider>
            <MnrText
              v-show="inputs.changeServer"
              v-model="inputs.server"
              placeholder="server"
              dir="ltr"
              class="w-full sm:w-8/12"
            />
          </div>
          <div class="mt-10" v-if="inputs.method == 'setWebhook'">
            <MnrText
              v-model="inputs.setWebhook.url"
              placeholder="url"
              dir="ltr"
              class="mb-3"
            />
            <MnrText
              v-model="inputs.setWebhook.ip"
              placeholder="ip_address"
              dir="ltr"
              class="mb-3"
            />
            <MnrNumber
              v-model="inputs.setWebhook.max"
              placeholder="max_connections"
              dir="ltr"
              class="mb-3"
            />
            <MnrCheckSlider v-model:checked="inputs.setWebhook.drop_pending_updates">
              drop_pending_updates
            </MnrCheckSlider>
          </div>
          <div class="mt-10" v-if="inputs.method == 'deleteWebhook'">
            <MnrCheckSlider v-model:checked="inputs.setWebhook.drop_pending_updates">
              drop_pending_updates
            </MnrCheckSlider>
          </div>
          <div class="mt-10" v-if="inputs.method == 'getUpdates'"></div>
          <div class="mt-10" v-if="inputs.method == 'close'"></div>
          <div class="mt-10" v-if="inputs.method == 'setMyCommands'"></div>
          <div class="mt-10" v-if="inputs.method == 'getMyCommands'"></div>
          <div class="mt-10" v-if="inputs.method == 'deleteMyCommands'"></div>
          <div
            class="flex flex-wrap gap-x-3"
            dir="ltr"
            v-if="inputs.method == 'getUpdates' || inputs.method == 'setWebhook'"
          >
            <hr class="my-3 border-blueGray-200 w-full" />
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="message">
              message
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="edited_message">
              edited_message
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="channel_post">
              channel_post
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="edited_channel_post"
            >
              edited_channel_post
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="inline_query">
              inline_query
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="chosen_inline_result"
            >
              chosen_inline_result
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="callback_query">
              callback_query
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="shipping_query">
              shipping_query
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="pre_checkout_query"
            >
              pre_checkout_query
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="poll">
              poll
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="poll_answer">
              poll_answer
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="my_chat_member">
              my_chat_member
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="chat_member">
              chat_member
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="chat_join_request"
            >
              chat_join_request
            </MnrCheckSlider>
          </div>
          <Button type="submit" class="mt-5" v-if="inputs.method != 'off'">اعمال</Button>
        </form>
      </div>
      <div class="p-3 bg-blueGray-100 border rounded-md border-blueGray-200 mb-5">
        <div class="p-3 bg-teal-700 text-teal-50">مشخصات ربات</div>
        <div
          v-for="(item, index) in bot"
          :key="index"
          class="p-3 flex flex-wrap gap-2 odd:bg-blueGray-200"
        >
          <div class="w-36 flex-shrink-0">{{ index }}</div>
          <div>{{ item }}</div>
        </div>
      </div>
      <div class="p-3 bg-blueGray-100 border rounded-md border-blueGray-200 mb-5">
        <div class="p-3 bg-teal-700 text-teal-50">getWebhookInfo</div>
        <div class="p-3 flex flex-wrap gap-2 odd:bg-blueGray-200">
          <div class="w-36 flex-shrink-0">ok</div>
          <div>{{ info.ok }}</div>
        </div>
        <div
          v-for="(item, index) in info.result"
          :key="index"
          class="p-3 flex flex-wrap gap-5 odd:bg-blueGray-200"
        >
          <div>{{ index }}</div>
          <div class="w-36 flex-shrink-0">{{ item }}</div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import Button from "@/components/Button.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
export default {
  components: { BodySimple, MnrText, MnrNumber, Button, MnrSelect, MnrCheckSlider },
  data() {
    return {
      firstLoading: true,
      bot: [],
      info: [],
      res: {},

      inputs: {
        method: "getWebhookInfo",
        changeServer: false,
        server: "",
        allowed_updates: [],
        setWebhook: {
          url: "",
          ip: "",
          max: "",

          drop_pending_updates: false,
        },
        deleteWebhook: {
          drop_pending_updates: false,
        },
        getUpdates: {},
      },
      options: {
        methods: [
          {
            text: "setWebhook",
            value: "setWebhook",
          },
          {
            text: "deleteWebhook",
            value: "deleteWebhook",
          },
          {
            text: "getUpdates",
            value: "getUpdates",
          },
          {
            text: "getWebhookInfo",
            value: "getWebhookInfo",
          },
          {
            text: "close",
            value: "close",
          },
          {
            text: "setMyCommands",
            value: "setMyCommands",
          },
          {
            text: "getMyCommands",
            value: "getMyCommands",
          },
          {
            text: "deleteMyCommands",
            value: "deleteMyCommands",
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
    this.getPending();
  },
  methods: {
    pretty(value) {
      return JSON.stringify(value, null, 2);
    },
    getData() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/admin/bots/" + $this.$route.params.botId)
        .then(function (response) {
          // console.log(response);
          $this.bot = response.data.bot;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
    getPending() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/admin/bots-info/" + $this.$route.params.botId)
        .then(function (response) {
          // console.log(response);
          $this.info = response.data.info;
          // console.log(_.find($this.info, ["id", 18])?.result?.pending_update_count);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
    submit() {
      let $this = this;
      $this.res = {};
      $this.$axios
        .post("/api/admin/bots-webhook/" + $this.$route.params.botId, {
          inputs: $this.inputs,
        })
        .then(function (response) {
          // console.log(response);
          $this.res = response.data;
          // $this.info = response.data.info;
          // console.log(_.find($this.info, ["id", 18])?.result?.pending_update_count);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
